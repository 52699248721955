import {
  SET_USER_DATA,
  SET_CARTS,
  SET_ADDRESSES,
  SET_CATEGORIES,
  SET_BRANDS,
  SET_COLLECTIONS,
  SET_CARTS_ITEM_DETAILS,
} from "lib/redux/type"

import {
  Q_AUTH_INITIAL,
  Q_BRAND_COLLECTION,
  Q_GUEST_INITIAL,
} from "lib/graphql"
import { initializeStore } from "lib/redux"
import { initializeApollo } from "lib/apollo"
import {
  CheckGeolocation,
  transformString,
  variantMapper2,
} from "./general-utils"
import { AsyncApollo } from "lib/functions"
import Cookies from "js-cookie"

export const fetchProductDetails = async (carts, Router) => {
  const detailProducts = []

  for (const product of carts.items) {
    const { data, errors } = await AsyncApollo({
      query: Q_BRAND_COLLECTION,
      variables: {
        lang: Router.locale,
        id: product.product_id,
        currency: Cookies.get(process.env.CURRENCY),
      },
    })

    if (data?.product) {
      detailProducts.push(data.product)
    }
  }

  return detailProducts?.map((item, i) => ({
    ...item,
    ...carts.items[i],
    variant: variantMapper2(carts.items[i]?.option_1, carts.items[i]?.option_2),
    listName: Router?.query?.listName || "All Products",
    listId: transformString(Router.query?.listName) || "all_products",
    position: i + 1,
  }))
}

export const getIntialData = async (token, status, Router) => {
  if (token) {
    const reduxStore = initializeStore()
    const { dispatch } = reduxStore
    const client = initializeApollo(token)
    let currency = await CheckGeolocation()
    let locale = Router.locale

    if (status) {
      //IF USER LOGIN
      let { data, errors } = await client.query({
        query: Q_AUTH_INITIAL,
        variables: { lang: locale, currency },
        errorPolicy: "all",
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
      })
      if (errors) window.location.href = "/500"
      if (data) {
        let { me, addresses, categories, carts, brands, collections } = data
        dispatch({ type: SET_USER_DATA, payload: me })
        dispatch({ type: SET_CARTS, payload: carts })
        dispatch({ type: SET_ADDRESSES, payload: addresses })
        dispatch({ type: SET_CATEGORIES, payload: categories })
        dispatch({ type: SET_BRANDS, payload: brands })
        dispatch({ type: SET_COLLECTIONS, payload: collections })
        // dispatch({ type: SET_COUNTRIES, payload: countries })
        if (carts) {
          const cartItemsWithDetail = await fetchProductDetails(carts, Router)
          dispatch({
            type: SET_CARTS_ITEM_DETAILS,
            payload: cartItemsWithDetail,
          })
          return { success: true }
        }
        return { success: true }
      }
    } else {
      //IF USER NOT
      let { data, errors } = await client.query({
        query: Q_GUEST_INITIAL,
        variables: { lang: locale, currency },
        errorPolicy: "all",
        fetchPolicy: "no-cache",
      })
      if (errors) window.location.href = "/500"
      if (data) {
        let { carts, categories, brands, collections } = data
        dispatch({ type: SET_CARTS, payload: carts })
        dispatch({ type: SET_CATEGORIES, payload: categories })
        dispatch({ type: SET_BRANDS, payload: brands })
        dispatch({ type: SET_COLLECTIONS, payload: collections })
        // dispatch({ type: SET_COUNTRIES, payload: countries })
        if (carts) {
          const cartItemsWithDetail = await fetchProductDetails(carts, Router)
          dispatch({
            type: SET_CARTS_ITEM_DETAILS,
            payload: cartItemsWithDetail,
          })
        }
        return { success: true }
      } else {
        return { success: false }
      }
    }
  } else {
    return { data: null, errors: "No Token" }
  }
}

// export const getInitialData = async (appContext, fn) => {
//   //Check Country
//   let currency = await CheckGeolocation(appContext.ctx)

//   //Validated token status;
//   let { token, user_status } = await TokenValidation(appContext.ctx)
//   //Define Redux
//   const reduxStore = initializeStore()
//   const { dispatch } = reduxStore

//   //Define Client
//   const client = initializeApollo(token)

//   //Locale lang
//   let { locale } = appContext.ctx

//   //Get Initial Data
//   if (typeof window === "undefined") {
//     try {
//       if (user_status) {
//         let { data, errors } = await client.query({
//           query: Q_AUTH_INITIAL,
//           variables: { lang: locale, currency },
//         })
//         if (data) {
// let {
//   me,
//   addresses,
//   wishlists,
//   categories,
//   carts,
//   countries,
//   brands,
// } = data
// dispatch({ type: SET_USER_DATA, payload: me })
// dispatch({ type: SET_CARTS, payload: carts })
// dispatch({ type: SET_ADDRESSES, payload: addresses })
// dispatch({ type: SET_WISHLIST, payload: wishlists })
// dispatch({ type: SET_CATEGORIES, payload: categories })
// dispatch({ type: SET_BRANDS, payload: brands })
// dispatch({ type: SET_COUNTRIES, payload: countries })
// return fn(appContext, reduxStore, token)
//         } else {
//           return { notFound: true }
//         }
//       } else {
//         let { data, errors } = await client.query({
//           query: Q_GUEST_INITIAL,
//           variables: { lang: locale, currency },
//           errorPolicy: "all",
//         })
//         if (data) {
//           let { carts, categories, countries, brands } = data
//           dispatch({ type: SET_CARTS, payload: carts })
//           dispatch({ type: SET_CATEGORIES, payload: categories })
//           dispatch({ type: SET_BRANDS, payload: brands })
//           dispatch({ type: SET_COUNTRIES, payload: countries })
//           return fn(appContext, reduxStore, token)
//         } else {
//           return {}
//         }
//       }
//     } catch (error) {
//       if (error?.networkError?.statusCode === 500) {
//         return { redirect: { destination: "/500", permanent: false } }
//       } else {
//         return { notFound: true }
//       }
//     }
//   } else {
//     return fn(appContext, reduxStore, token)
//   }
// }
